import React from 'react';
import Notifications from '@sm360/crm-notifications';
import { CreateResourceButton } from '@sm360/crm-create-resource';
import { Support360 } from '@sm360/apps-core';

const TopBar = ({ context, name, userPanel: UserPanel }) => {
    const { state } = React.useContext(context);
    return (
        <>
            <div className="uk-topbar__left">
                <CreateResourceButton className="uk-topbar__quick-create hide-for-medium" />
                <Notifications organization={state.organization} user={state.user} />
            </div>
            <div className="uk-topbar__right">
                <CreateResourceButton className="uk-topbar__quick-create show-for-medium" />
                <UserPanel context={context} name={name} />
                <Support360 />
            </div>
        </>
    );
};

export default TopBar;
