"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLeadTypeAllowedInGroup = exports.isCallExpired = exports.internallyDismissNotification = exports.groupByKey = exports.getNotificationIdsByLeadId = exports.getGroupIdByLeadType = exports.formatContactName = exports.computeLeadGroups = void 0;
var _constants = require("./constants");
// Returns a filtered list of allEmails having a certain leadId
const getNotificationIdsByLeadId = (allEmails, leadId) => {
  const notificationsWithSameLead = allEmails.filter(n => n.leadId === leadId);
  return notificationsWithSameLead.map(n => n.activityId);
};

// Returns if a certain date is older than 20 minutes
exports.getNotificationIdsByLeadId = getNotificationIdsByLeadId;
const isCallExpired = dateCreated => {
  if (typeof dateCreated !== 'string') return false;
  const notifDate = new Date(dateCreated).getTime();
  const now = new Date();
  const diffMs = now.getTime() - notifDate;
  const diffMins = Math.round(diffMs % 86400000 % 3600000 / 60000); // minutes
  return Math.abs(diffMins) >= 20;
};

// Dispatch a CustomEvent to dismiss a notification in all NotificationsProviders of the browser
exports.isCallExpired = isCallExpired;
const internallyDismissNotification = (type, id) => {
  const event = new CustomEvent(`dismiss_${type}`, {
    detail: {
      message: JSON.stringify(id)
    }
  });
  dispatchEvent(event);
};
exports.internallyDismissNotification = internallyDismissNotification;
const formatContactName = (name, t) => {
  if (!name || name?.length === 0) return t('commons.NA');
  return String(name).replace(',', ' ').trim();
};
exports.formatContactName = formatContactName;
const isLeadTypeAllowedInGroup = (leadType, group) => (group.excludedTypes.length > 0 ? !group.excludedTypes.includes(leadType) : true) && (group.includedTypes.length > 0 ? group.includedTypes.includes(leadType) : true);
exports.isLeadTypeAllowedInGroup = isLeadTypeAllowedInGroup;
const computeLeadGroups = function () {
  let groupBy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const typeGroups = groupBy.map(id => _constants.LEAD_GROUPS?.[id]).filter(group => !!group);

  // ensure the base group never display what the other groups are displaying
  const allIncludedTypes = typeGroups.reduce((acc, group) => [...acc, ...(group?.includedTypes || [])], []);
  return [{
    ..._constants.LEAD_GROUPS.BASE,
    excludedTypes: allIncludedTypes
  }, ...typeGroups];
};
exports.computeLeadGroups = computeLeadGroups;
const getGroupIdByLeadType = (leadType, groups) => groups.find(group => isLeadTypeAllowedInGroup(leadType, group))?.id;
exports.getGroupIdByLeadType = getGroupIdByLeadType;
const groupByKey = (list, key, _ref) => {
  let {
    omitKey = false
  } = _ref;
  return list.reduce((hash, _ref2) => {
    let {
      [key]: value,
      ...rest
    } = _ref2;
    return {
      ...hash,
      [value]: (hash[value] || []).concat(omitKey ? {
        ...rest
      } : {
        [key]: value,
        ...rest
      })
    };
  }, {});
};
exports.groupByKey = groupByKey;