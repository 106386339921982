"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _uuid = require("uuid");
var _commonsUtils = require("@sm360/commons-utils");
var _useIsMobile = _interopRequireDefault(require("./useIsMobile"));
var _useMobileSendEvent = _interopRequireDefault(require("./useMobileSendEvent"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Allow opening a Blob file in a new tab or saving it
 */
const useOpenBlobFile = () => {
  const isMobile = (0, _useIsMobile.default)();
  const sendMessageToNative = (0, _useMobileSendEvent.default)();
  const handleOpen = (0, _react.useCallback)((blobFile, filename, enableDownload) => {
    if (isMobile) {
      const reader = new FileReader();
      reader.readAsDataURL(blobFile);
      reader.onloadend = function () {
        const base64data = reader.result;
        const fileId = (0, _uuid.v4)();

        // Send data by chunks to overcome the postMessage message size limit
        const chunkSize = 60 * 1024; // 60 KB chunks for safety (less than the 64 KB iOS limit)
        const totalChunks = Math.ceil(base64data.length / chunkSize);
        for (let i = 0; i < totalChunks; i++) {
          const chunk = base64data.slice(i * chunkSize, (i + 1) * chunkSize);
          sendMessageToNative(_commonsUtils.MOBILE_CRM_REQUEST_TYPES.DOWNLOAD, {
            fileId,
            filename,
            chunk: chunk,
            chunkIndex: i,
            totalChunks: totalChunks
          });
        }
      };
    } else {
      const url = URL.createObjectURL(blobFile);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      if (enableDownload) link.download = filename || 'file';
      link.click();
    }
  }, [isMobile, sendMessageToNative]);
  const openFile = (0, _react.useCallback)((blobFile, filename) => handleOpen(blobFile, filename, false), [handleOpen]);
  const downloadFile = (0, _react.useCallback)((blobFile, filename) => handleOpen(blobFile, filename, true), [handleOpen]);
  return {
    openFile,
    downloadFile
  };
};
var _default = exports.default = useOpenBlobFile;