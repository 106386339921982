"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
const appointmentPrefix = 'actions.appointment.form';
const placeholderPrefix = 'actions.placeholders';
const sharedPrefix = 'actions.shared';
const customMessage = {
  required: 'components.uikit.validation.required'
};
const commonProps = {
  customMessage
};
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    startDate: {
      title: `${appointmentPrefix}.START_DATE`,
      type: 'string',
      showTimeInput: true,
      ioFormat: 'Timestamp',
      shouldCloseOnSelect: false,
      timeInputLabel: `${appointmentPrefix}.TIME`,
      datePickerStartYear: 2000,
      extraSelectableYears: 10,
      ...commonProps
    },
    endDate: {
      title: `${appointmentPrefix}.END_DATE`,
      type: 'string',
      showTimeInput: true,
      ioFormat: 'Timestamp',
      shouldCloseOnSelect: false,
      timeInputLabel: `${appointmentPrefix}.TIME`,
      datePickerStartYear: 2000,
      extraSelectableYears: 10,
      ...commonProps
    },
    assignedUserId: {
      title: `${appointmentPrefix}.OWNER`,
      type: 'string',
      enum: [],
      placeholder: `${placeholderPrefix}.SELECT_EMPLOYEE`,
      clearable: true,
      ...commonProps
    },
    assignedSalespersonId: {
      title: `${appointmentPrefix}.SALESPERSON`,
      type: 'string',
      enum: [],
      placeholder: `${placeholderPrefix}.SELECT_EMPLOYEE`,
      clearable: true,
      ...commonProps
    },
    assignedManagerId: {
      title: `${appointmentPrefix}.BUSINESS_MANAGER`,
      type: 'string',
      enum: [],
      placeholder: `${placeholderPrefix}.SELECT_EMPLOYEE`,
      clearable: true,
      ...commonProps
    },
    appointmentType: {
      title: '',
      type: 'object',
      properties: {
        id: {
          title: `${appointmentPrefix}.APPOINTMENT_TYPE`,
          type: 'string',
          enum: [],
          placeholder: `${placeholderPrefix}.SELECT_APPOINTMENT_TYPE`,
          ...commonProps
        }
      },
      required: ['id']
    },
    activityStatusId: {
      title: `${sharedPrefix}.activityStatuses.TITLE`,
      type: 'string',
      default: 1,
      enum: [1, 3, 4, 5, 6, 7, 8],
      enumNames: [`${sharedPrefix}.activityStatuses.OPTIONS.ACTIVE`, `${sharedPrefix}.activityStatuses.OPTIONS.CANCELED`, `${sharedPrefix}.activityStatuses.OPTIONS.COMPLETED`, `${sharedPrefix}.activityStatuses.OPTIONS.NA`, `${sharedPrefix}.activityStatuses.OPTIONS.DELETED`, `${sharedPrefix}.activityStatuses.OPTIONS.INVALID`, `${sharedPrefix}.activityStatuses.OPTIONS.DUPLICATE`],
      ...commonProps
    },
    guests: {
      title: `${appointmentPrefix}.GUESTS`,
      type: 'string',
      enum: [],
      placeholder: `${placeholderPrefix}.ADD_GUEST`,
      description: `${appointmentPrefix}.GUESTS_DESCRIPTION`,
      descriptionPosition: 'left',
      clearable: true,
      maxMenuHeight: 250,
      validNewOptionRegex: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ...commonProps
    },
    location: {
      title: `${appointmentPrefix}.LOCATION`,
      type: 'string',
      name: 'appointment-location',
      autocomplete: true,
      clearable: true,
      ...commonProps
    },
    subject: {
      title: `${sharedPrefix}.SUBJECT`,
      type: 'string',
      name: 'appointment-subject',
      autocomplete: true,
      clearable: true,
      ...commonProps
    },
    details: {
      title: `${appointmentPrefix}.DETAILS`,
      type: 'string',
      clearable: true,
      ...commonProps
    }
  },
  required: ['details', 'subject', 'startDate', 'endDate', 'appointmentType', 'assignedUserId']
};
const UiSchema = exports.UiSchema = {
  startDate: {
    'ui:widget': 'dateWidget'
  },
  endDate: {
    'ui:widget': 'dateWidget'
  },
  details: {
    'ui:widget': 'textareaWidget'
  },
  assignedUserId: {},
  assignedSalespersonId: {},
  assignedManagerId: {},
  appointmentType: {},
  activityStatusId: {},
  guests: {
    'ui:widget': 'CreatableSelectWidget'
  },
  location: {},
  subject: {}
};