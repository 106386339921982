"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
const customMessage = {
  required: 'components.uikit.validation.required'
};
const commonProps = {
  customMessage
};
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    notes: {
      title: 'actions.note.form.NOTES',
      type: 'string',
      ...commonProps
    }
  },
  required: ['notes']
};
const UiSchema = exports.UiSchema = {
  notes: {
    'ui:widget': 'textareaWidget',
    'ui:autofocus': true
  }
};