"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PROFILE_FORM = void 0;
const translationPrefix = 'contacts.forms.profile';
const PROFILE_FORM = exports.PROFILE_FORM = [{
  legend: `${translationPrefix}.identity.TITLE`,
  fields: [{
    input: 'profile',
    className: 'small-12 medium-4 large-3',
    skipLayout: true
  }
  // {},
  // {
  //     input: 'datePicker',
  //     className: 'small-12 medium-4 large-3',
  //     label: `${translationPrefix}.identity.BIRTH_DATE`,
  //     name: 'dateOfBirth',
  //     ioFormat: 'unixTimestamp',
  // },
  ]
}, {
  legend: `${translationPrefix}.contactInformation.TITLE`,
  fields: [{
    input: 'language',
    className: 'small-12 medium-4 large-3 align-self-top'
  }, {
    input: 'optInPreferences',
    className: 'small-12 medium-4 large-3'
  }, {}, {
    input: 'select',
    blockLabel: `${translationPrefix}.contactInformation.PREFERRED_CONTACT_MOMENT`,
    className: 'small-12 medium-3',
    name: 'preferredContactMoment',
    optionLabelValue: 'label'
  }, {
    input: 'select',
    className: 'small-12 medium-3',
    name: 'preferredContactTime',
    optionLabelValue: 'label'
  }, {}, {
    input: 'phones',
    className: 'small-12'
  }, {
    input: 'emails',
    className: 'small-12'
  }, {
    input: 'textarea',
    className: 'small-12 medium-6',
    name: 'notes',
    label: 'Notes'
  }]
}, {
  legend: `${translationPrefix}.localization.TITLE`,
  fields: [{
    input: 'input',
    className: 'small-12 medium-8',
    label: `${translationPrefix}.localization.ADDRESS`,
    name: 'address'
  }, {
    input: 'input',
    className: 'small-12 medium-8',
    label: '',
    name: 'address2'
  }, {}, {
    input: 'postalCode',
    className: 'small-12 medium-3'
  }, {
    input: 'input',
    className: 'small-12 medium-5',
    label: `${translationPrefix}.localization.CITY`,
    name: 'city'
  }, {}, {
    input: 'territories',
    className: 'small-12 medium-4',
    label: `${translationPrefix}.localization.PROVINCE`,
    name: 'province',
    optionLabelValue: 'label'
  }, {
    input: 'select',
    className: 'small-12 medium-4',
    label: `${translationPrefix}.localization.COUNTRY`,
    name: 'country',
    optionLabelValue: 'label'
  }]
}, {
  legend: `${translationPrefix}.contactContactTags.TITLE`,
  fieldset: '',
  fields: [{
    input: 'contactContactTags',
    label: `${translationPrefix}.contactContactTags.TAGS`,
    className: 'small-12 medium-5',
    name: 'contactContactTags',
    optionLabelValue: 'label'
  }]
}];