import React from 'react';
import {
    useTranslation,
    useAuth,
    useMultipleCheckRights,
    useGravatarPicture,
    LangSwitcher,
    ChangePassword,
    ResetQueryCacheButton,
} from '@sm360/apps-core';
import { Drawer, Button, Tabs, Avatar, Toast, Icon, Input, Popover, CheckboxGroup, Anchor } from '@sm360/ui-library';
import ProfilePhoneNumbers from '@sm360/crm-profile-phone-numbers';
import ProfileSignatures from '@sm360/crm-profile-signatures';
import ProfileImapConfig, { ConnectionIssueTooltip } from '@sm360/crm-profile-imap-config';
import ProfileLeadNotificationConfig from '@sm360/crm-profile-lead-notification-config';

const Trigger = ({ organization, user, picture, pictureBadge }) => {
    return (
        <div className="grid-x align-middle">
            <div className={`cell shrink ${pictureBadge ? 'uk-subscript-badge' : ''}`}>
                <Avatar imageUrl={picture} />
                {pictureBadge && <div className="uk-subscript-badge__inner">{pictureBadge}</div>}
            </div>
            <small className="cell shrink margin-left-1 text-left show-for-medium">
                {`${user.firstname} ${user.lastname}`}
                <br />
                <small>{organization.name}</small>
            </small>
        </div>
    );
};

const PrefPanel = ({ orgId }) => {
    const { t } = useTranslation();
    const { authUser } = useAuth();
    const [toClear, setToClear] = React.useState([]);
    const LS = JSON.parse(localStorage.getItem(`${authUser.profile.sub}@${orgId}`));

    const handleDelete = () => {
        const newPrefs = { ...LS };
        for (let index = 0; index < toClear.length; index++) {
            const item = toClear[index];
            const shortItem = item.split('--')[1];
            delete newPrefs[shortItem];
        }
        localStorage.setItem(`${authUser.profile.sub}@${orgId}`, JSON.stringify(newPrefs));
        window.location.reload();
    };

    return (
        <div className="uk-form-template">
            <CheckboxGroup
                className="uk-form-field"
                onChange={(v) => setToClear(v)}
                checked={toClear}
                options={Object.keys(LS)
                    .filter((item) => {
                        const orgRef = item.split('@')[1];
                        return Number(orgRef) === Number(orgId) || !orgRef;
                    })
                    .map((key) => {
                        const keyRef = key.split('@')[0];
                        return {
                            id: 'userPref--' + key,
                            value: key,
                            label: keyRef || key,
                        };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label))}
            />
            <Button
                onClick={handleDelete}
                size="small"
                outline
                label={t('Delete selected preferences')}
                disabled={toClear.length < 1}
            />
        </div>
    );
};

const UserPanel = ({ context, name }) => {
    const { t, locale } = useTranslation();
    const { authUser, logout } = useAuth();
    const { state, dispatch } = React.useContext(context);
    const userPicture = useGravatarPicture(state.user.email);
    const userRights = useMultipleCheckRights(state.user, ['AccountManager', 'Admin', 'IvrOutbound', 'CRMLite']);
    const userCanMakePhoneCalls = userRights?.IvrOutbound;
    const userOnlyHasAccessToCrmLite = userRights?.CRMLite && state.user?.roles?.length === 1;
    const userExtraFeaturesEnabled = !userOnlyHasAccessToCrmLite;

    const copyToClipboard = async (authUser) => {
        if (!navigator.clipboard || !authUser) return;

        try {
            await navigator.clipboard.writeText(authUser.id_token);
            Toast.toast.success(t('app.user.TOKEN_COPY_SUCCESS'));
        } catch (error) {
            Toast.toast.error(t('app.user.TOKEN_COPY_ERROR') + error);
        }
    };

    const emailSyncWarning = <ConnectionIssueTooltip userId={state.user?.id} />;

    return (
        <div className="uk-topbar__user">
            <Drawer
                trigger={
                    <Button className="text-left" displayAsLink color="white" size="large">
                        <Trigger
                            user={state.user}
                            organization={state.organization}
                            picture={userPicture.url}
                            pictureBadge={emailSyncWarning}
                        />
                    </Button>
                }
                drawerScopeCSS="uk-user-panel"
                className="user__drawer"
                tabIndex={3}
            >
                {({ setClose }) => (
                    <>
                        <div className="user-info">
                            <div className="user-info__head">
                                <div className="user-info__avatar">
                                    <Avatar imageUrl={userPicture.url} />
                                    {!userPicture.hasImage && (
                                        <Popover
                                            position="left"
                                            displayOnHover
                                            trigger={
                                                <Button
                                                    shape="round"
                                                    icon="plus"
                                                    onClick={() =>
                                                        window.open(`https://${locale}.gravatar.com`, '_blank')?.focus()
                                                    }
                                                />
                                            }
                                        >
                                            {t('app.user.GRAVATAR_CTA')}
                                            {locale === 'fr' && process.env.REACT_APP_GRAVATAR_HELP_URL_FR !== undefined && (
                                                <Anchor
                                                    to={process.env.REACT_APP_GRAVATAR_HELP_URL_FR}
                                                    target="_blank"
                                                    withNoOpener
                                                    withNoReferrer
                                                >
                                                    {t('app.user.GRAVATAR_HELP_CTA')}
                                                </Anchor>
                                            )}
                                            {locale === 'en' && process.env.REACT_APP_GRAVATAR_HELP_URL_EN !== undefined && (
                                                <Anchor
                                                    to={process.env.REACT_APP_GRAVATAR_HELP_URL_EN}
                                                    target="_blank"
                                                    withNoOpener
                                                    withNoReferrer
                                                >
                                                    {t('app.user.GRAVATAR_HELP_CTA')}
                                                </Anchor>
                                            )}
                                        </Popover>
                                    )}
                                </div>
                                {(userRights?.AccountManager || userRights?.Admin) && <ResetQueryCacheButton />}
                                <LangSwitcher />
                                <Button color="white" outline onClick={logout}>
                                    <i className="fa fa-sign-out-alt" />
                                </Button>
                            </div>
                            <div className="user-info__body">
                                <div className="user-info__base">
                                    {authUser && (
                                        <div className="user-info__avatar">
                                            <strong>
                                                {authUser.profile.firstname} {authUser.profile.lastname}
                                            </strong>
                                        </div>
                                    )}
                                    <div className="user-info__organization organization">
                                        <p className="organization__legal-name">{state.organization.name}</p>
                                        <Button
                                            color="primary"
                                            displayAsLink
                                            size="small"
                                            className="organization__select"
                                            onClick={() => dispatch({ type: 'unsetOrganization' })}
                                        >
                                            {t('app.user.CTA_CHANGE_ORGANIZATION')}
                                        </Button>
                                    </div>
                                </div>
                                {userExtraFeaturesEnabled && (
                                    <div className="uk-form-template">
                                        <Tabs fullWidth>
                                            <div
                                                trigger={
                                                    <div>
                                                        <Icon name="user-circle" />
                                                        <span>{t('app.user.PROFILE')}</span>
                                                    </div>
                                                }
                                            >
                                                {/* <div className="uk-form-template"> */}
                                                <fieldset>
                                                    <div className="uk-form-field">
                                                        <Input
                                                            label={t('app.user.FULL_NAME')}
                                                            value={`${authUser.profile.firstname} ${authUser.profile.lastname}`}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="uk-form-field grid-x grid-margin-x">
                                                        <div className="cell auto">
                                                            <Input
                                                                label={t('app.user.EMAIL')}
                                                                type="text"
                                                                value={authUser.profile.email}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="cell shrink">
                                                            <ChangePassword
                                                                authUser={authUser}
                                                                organization={state.organization}
                                                            />
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                {/* </div> */}
                                            </div>
                                            <div
                                                trigger={
                                                    <div>
                                                        <i className="uk-svg fa fa-solid fa-gear" />
                                                        <span>{t('app.user.EMAIL')}</span>
                                                        <i className="uk-svg" />
                                                        <span>{emailSyncWarning}</span>
                                                    </div>
                                                }
                                            >
                                                <ProfileSignatures />
                                                <ProfileImapConfig />
                                            </div>
                                            {userCanMakePhoneCalls && (
                                                <div
                                                    trigger={
                                                        <div>
                                                            <i className="uk-svg fa fa-solid fa-gear" />
                                                            <span>{t('app.user.PHONE')}</span>
                                                        </div>
                                                    }
                                                >
                                                    <ProfilePhoneNumbers />
                                                </div>
                                            )}
                                            <div
                                                trigger={
                                                    <div>
                                                        <i className="uk-svg fa fa-solid fa-gear" />
                                                        <span>{t('app.user.NOTIFICATIONS')}</span>
                                                    </div>
                                                }
                                            >
                                                <ProfileLeadNotificationConfig />
                                            </div>
                                        </Tabs>
                                    </div>
                                )}
                                {process.env.REACT_APP_DEV_INFORMATIONS && authUser && (
                                    <Tabs fullWidth className="margin-top-2">
                                        <div trigger="ID Token">
                                            <div className="jwt">
                                                <small style={{ wordBreak: 'break-all' }}>{authUser.id_token}</small>
                                                <div className="margin-top-1 text-center">
                                                    <Button
                                                        onClick={() => copyToClipboard(authUser)}
                                                        size="small"
                                                        label={t('app.user.CTA_TOKEN')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div trigger="Application State">
                                            <small>
                                                <pre>{JSON.stringify(state, null, 4)}</pre>
                                            </small>
                                        </div>
                                        <div trigger="OIDC State">
                                            <small>
                                                <pre>{JSON.stringify(authUser, null, 4)}</pre>
                                            </small>
                                        </div>
                                        <div trigger={t('app.user.PREFERENCES')}>
                                            <PrefPanel orgId={state.organization.id} />
                                        </div>
                                    </Tabs>
                                )}
                            </div>
                        </div>
                        <div className="legal">
                            <div>
                                <a href={t('app.legal.PRIVACY_URL')} target="_blank" rel="noopener noreferrer">
                                    {t('app.legal.PRIVACY')}
                                </a>
                                &nbsp;|&nbsp;
                                <a href={t('app.legal.TERMS_URL')} target="_blank" rel="noopener noreferrer">
                                    {t('app.legal.TERMS')}
                                </a>
                                &nbsp;|&nbsp;
                                <span>v{sessionStorage.getItem(name)}</span>
                            </div>
                            <Button outline size="small" onClick={setClose}>
                                <i className="fa fa-close" />
                                <span>{t('commons.actions.CLOSE')}</span>
                            </Button>
                        </div>
                    </>
                )}
            </Drawer>
        </div>
    );
};

export default UserPanel;
