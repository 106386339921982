"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _commonsUtils = require("@sm360/commons-utils");
var _useIsMobile = _interopRequireDefault(require("./useIsMobile"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Listen for mobile app events
 * @param {String} type event type (one of MOBILE_CRM_REQUEST_TYPES, etc...)
 * @param {Function} onSuccess callback when a `type` event is heard and is successful
 * @param {Function} onError callback when a `type` event is heard and has an error
 */
const useMobileEvent = (type, onSuccess, onError) => {
  const isMobile = (0, _useIsMobile.default)();
  (0, _react.useEffect)(() => {
    const listener = nativeEvent => {
      if (nativeEvent.data && isMobile) {
        try {
          const mobileEvent = JSON.parse(nativeEvent.data) || {};
          if (mobileEvent.type === type && mobileEvent.origin === 'CrmMobileApp') {
            // `mobileEvent` has the following structure:
            // { origin: 'CrmMobileApp', type: 'one of MOBILE_CRM_REQUEST_TYPES', data: null, status: 'success' or 'error' }

            if (mobileEvent.status === 'success' && (0, _commonsUtils.validFunction)(onSuccess)) onSuccess(mobileEvent);
            if (mobileEvent.status === 'error' && (0, _commonsUtils.validFunction)(onError)) onError(mobileEvent);
          }
        } catch {}
      }
    };
    document.addEventListener('message', listener);
    window.addEventListener('message', listener);
    return () => {
      document.removeEventListener('message', listener);
      window.removeEventListener('message', listener);
    };
  }, [onSuccess, onError, type, isMobile]);
};
var _default = exports.default = useMobileEvent;