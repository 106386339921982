"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MOBILE_CRM_REQUEST_TYPES = void 0;
// All request types/events that can occur between rap and mobile app
// Communication can be uni or bidirectional:
// * rap  --> mobile    rap sends request to mobile and is not expecting a response back
// * rap <--  mobile    mobile sends request to rap and is not expecting a response back
// * rap <--> mobile    rap sends request to mobile and is expecting a response back (success or error) or the other way around

const MOBILE_CRM_REQUEST_TYPES = exports.MOBILE_CRM_REQUEST_TYPES = {
  // rap --> mobile (allow mobile to display its overlay if any available for the active route)
  ALLOW_OVERLAY: 'allow-overlay',
  // rap --> mobile
  DOWNLOAD: 'download',
  // rap --> mobile (set the header title of the mobile screen)
  SET_SCREEN_TITLE: 'set-screen-title',
  // rap (listen for success and driver data) <--> mobile (open driver license scanner)
  SCAN_DRIVER_LICENSE: 'scan-driver-license',
  // rap (listen for success and vin data) <--> mobile (open vin scanner)
  SCAN_VIN: 'scan-vin',
  // rap <-- mobile
  OPEN_ACTION_PHONE: 'open-action-phone',
  // rap <-- mobile
  OPEN_ACTION_EMAIL: 'open-action-email',
  // rap <-- mobile
  OPEN_ACTION_SMS: 'open-action-sms',
  // rap <-- mobile
  OPEN_ACTION_APPOINTMENT: 'open-action-appointment',
  // rap <-- mobile
  OPEN_ACTION_TASK: 'open-action-task',
  // rap <-- mobile
  OPEN_ACTION_NOTE: 'open-action-note',
  // rap <-- mobile
  CALENDAR_REFRESH: 'calendar-refresh',
  // rap <-- mobile
  CALENDAR_SWITCH_VIEW: 'calendar-switch-view'
};