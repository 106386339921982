"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiSchema = exports.Schema = void 0;
const sendEmailPrefix = 'actions.sendEmail.form';
const placeholderPrefix = 'actions.placeholders';
const customMessage = {
  required: 'components.uikit.validation.required'
};
const commonProps = {
  customMessage
};
const creatableSelectProps = {
  createLabel: 'commons.actions.ADD',
  clearable: true,
  noDropdownIcon: true,
  noOptionsMessage: true,
  validNewOptionRegex: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
const Schema = exports.Schema = {
  type: 'object',
  properties: {
    to: {
      title: `${sendEmailPrefix}.TO`,
      type: 'string',
      otherLabel: `${sendEmailPrefix}.OTHER`,
      ...commonProps
    },
    replyTo: {
      title: `${sendEmailPrefix}.FROM`,
      type: 'string',
      ...commonProps
    },
    cc: {
      title: `${sendEmailPrefix}.CC`,
      type: 'string',
      enum: [],
      ...commonProps,
      ...creatableSelectProps
    },
    bcc: {
      title: `${sendEmailPrefix}.BCC`,
      type: 'string',
      enum: [],
      ...creatableSelectProps,
      ...commonProps
    },
    subject: {
      title: `${sendEmailPrefix}.SUBJECT`,
      type: 'string',
      name: 'email-subject',
      autocomplete: true,
      ...commonProps
    },
    mailContent: {
      title: `${sendEmailPrefix}.MAIL_CONTENT`,
      type: 'string',
      config: 'full',
      ...commonProps
    },
    attachments: {
      title: '',
      type: 'string',
      mimeType: '*',
      multiple: true,
      placeholder: `${placeholderPrefix}.ATTACHMENTS`,
      ...commonProps
    },
    signatures: {
      title: `${sendEmailPrefix}.SIGNATURE`,
      type: 'string',
      placeholder: `${placeholderPrefix}.SELECT_SIGNATURE`,
      enum: [],
      enumNames: [],
      ...commonProps
    },
    mailTemplate: {
      title: `${sendEmailPrefix}.TEMPLATE`,
      type: 'string',
      placeholder: `${placeholderPrefix}.SELECT_MAIL_TEMPLATE`,
      ...commonProps
    },
    mailTemplateLanguage: {
      title: `${sendEmailPrefix}.TEMPLATE_LANGUAGE`,
      enum: ['en', 'fr'],
      displayAsButton: true,
      color: 'secondary',
      ...commonProps
    },
    subscriptionContext: {
      title: `${sendEmailPrefix}.SUBSCRIPTION_CONTEXT`,
      type: 'string',
      enum: [],
      enumNames: [],
      placeholder: `${placeholderPrefix}.SELECT_SUBSCRIPTION_CONTEXT`,
      ...commonProps
    }
  },
  required: ['to', 'replyTo', 'subject', 'mailContent', 'subscriptionContext']
};
const UiSchema = exports.UiSchema = {
  to: {
    'ui:type': 'email'
  },
  replyTo: {
    'ui:type': 'email'
  },
  cc: {
    'ui:widget': 'CreatableSelectWidget'
  },
  bcc: {
    'ui:widget': 'CreatableSelectWidget'
  },
  subject: {},
  mailContent: {
    'ui:widget': 'EditorWidget'
  },
  attachments: {
    'ui:widget': 'UploaderWidget'
  },
  mailTemplate: {},
  mailTemplateLanguage: {
    'ui:widget': 'RadioGroupWidget'
  },
  subscriptionContext: {},
  signatures: {}
};